import { Union, Record } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { union_type, record_type, option_type, bool_type, string_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { createElement } from "react";
import * as react from "react";
import Facebook from "@material-ui/icons/Facebook";
import { printf, toText } from "../Server/.fable/fable-library.3.1.12/String.js";
import WhatsApp from "@material-ui/icons/WhatsApp";
import Twitter from "@material-ui/icons/Twitter";
import Reddit from "@material-ui/icons/Reddit";
import { ofArray } from "../Server/.fable/fable-library.3.1.12/List.js";
import { Interop_reactApi } from "../Server/.fable/Feliz.1.60.0/Interop.fs.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Slide from "@material-ui/core/Slide";
import { createObj } from "../Server/.fable/fable-library.3.1.12/Util.js";
import { collect, singleton, append, delay, toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";
import { defaultArg } from "../Server/.fable/fable-library.3.1.12/Option.js";
import Tooltip from "@material-ui/core/Tooltip";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import Share from "@material-ui/icons/Share";
import FileCopy from "@material-ui/icons/FileCopy";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import { navigatorCanShare } from "./Utils.fs.js";

export class ShareState extends Record {
    constructor(CanonicalUrl, IsShown, LinkMsg, IsOpen) {
        super();
        this.CanonicalUrl = CanonicalUrl;
        this.IsShown = IsShown;
        this.LinkMsg = LinkMsg;
        this.IsOpen = IsOpen;
    }
}

export function ShareState$reflection() {
    return record_type("Share.ShareState", [], ShareState, () => [["CanonicalUrl", string_type], ["IsShown", bool_type], ["LinkMsg", option_type(string_type)], ["IsOpen", bool_type]]);
}

export class ShareMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OpenShare", "CloseShare", "CopyLink", "SetShareMsg", "ResetShareMsg", "NavigatorShare"];
    }
}

export function ShareMsg$reflection() {
    return union_type("Share.ShareMsg", [], ShareMsg, () => [[], [], [], [["Item", string_type]], [], []]);
}

export function viewShareContent(state, dispatch) {
    let children_6;
    const encodedUrl = encodeURIComponent(state.CanonicalUrl);
    const shareButtons = ofArray([["Facebook", react.createElement(Facebook, {}), toText(printf("https://www.facebook.com/sharer.php?display=page\u0026u=%s\u0026hashtag=%%23facemorph"))(encodedUrl)], ["WhatsApp", react.createElement(WhatsApp, {}), toText(printf("https://api.whatsapp.com/send?text=%s"))(encodedUrl)], ["Twitter", react.createElement(Twitter, {}), toText(printf("https://twitter.com/share?url=%s\u0026hashtags=facemorph"))(encodedUrl)], ["Reddit", react.createElement(Reddit, {}), toText(printf("https://reddit.com/submit?url=%s"))(encodedUrl)]]);
    return createElement("div", {
        style: {
            textAlign: "right",
            padding: 2 + "em",
            overflow: "hidden",
            marginBottom: "clamp(-2em - 45px, 5 * (700px - 100%), 0px)",
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Slide, [["in", state.IsShown], ["direction", "left"], ["exit", false], ["children", (children_6 = ofArray([createElement("p", createObj(toList(delay(() => append(singleton(["style", {
            marginBottom: 10 + "px",
        }]), delay(() => singleton(["children", defaultArg(state.LinkMsg, "Share this morph")]))))))), MuiHelpers_createElement(Tooltip, [["title", "Copy link"], ["placement", "bottom-end"], ["children", MuiHelpers_createElement(SpeedDial, [["icon", MuiHelpers_createElement(SpeedDialIcon, [["icon", react.createElement(Share, {})], ["openIcon", react.createElement(FileCopy, {})]])], ["ariaLabel", "Share this morph"], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => collect((matchValue) => {
            const name_2 = matchValue[0];
            return singleton(MuiHelpers_createElement(SpeedDialAction, [["icon", matchValue[1]], ["key", name_2], ["tooltipTitle", name_2], ["tooltipPlacement", "bottom"], ["FabProps", {
                href: matchValue[2],
                target: "_blank",
                rel: "noopener",
            }]]));
        }, shareButtons)))))], ["open", state.IsOpen], ["onOpen", (delegateArg0, delegateArg1) => {
            dispatch(new ShareMsg(0));
        }], ["onClose", (delegateArg0_1, delegateArg1_1) => {
            dispatch(new ShareMsg(1));
        }], ["direction", "left"], ["FabProps", {
            onClick: (e) => {
                e.preventDefault();
                if (state.IsOpen) {
                    dispatch(new ShareMsg(2));
                }
                else if (navigatorCanShare) {
                    dispatch(new ShareMsg(5));
                }
            },
        }]])]])]), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children_6)),
        }))]])]),
    });
}

