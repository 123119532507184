import { FSharpRef, Record } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { record_type, option_type, list_type, class_type, bool_type, lambda_type, unit_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { CheckfaceSrc, CheckfaceSrc$reflection } from "./Checkface.fs.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../Server/.fable/Feliz.1.60.0/Interop.fs.js";
import { tryParse } from "../Server/.fable/fable-library.3.1.12/Guid.js";
import { tryToOption } from "./Utils.fs.js";
import { some, defaultArg } from "../Server/.fable/fable-library.3.1.12/Option.js";
import { React_createDisposable_3A5B6456, useReact_useEffect_Z5234A374, useReact_useElementRef } from "../Server/.fable/Feliz.1.60.0/React.fs.js";
import { equals } from "../Server/.fable/fable-library.3.1.12/Util.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import { empty, singleton, delay, toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";
import { browseFacesEmbedSrc } from "./Config.fs.js";

export class BrowseFacesDialogProps extends Record {
    constructor(OnClose, IsOpen, RenderValue, OnValueSelected, Values) {
        super();
        this.OnClose = OnClose;
        this.IsOpen = IsOpen;
        this.RenderValue = RenderValue;
        this.OnValueSelected = OnValueSelected;
        this.Values = Values;
    }
}

export function BrowseFacesDialogProps$reflection() {
    return record_type("BrowseFacesDialog.BrowseFacesDialogProps", [], BrowseFacesDialogProps, () => [["OnClose", lambda_type(unit_type, unit_type)], ["IsOpen", bool_type], ["RenderValue", lambda_type(CheckfaceSrc$reflection(), class_type("Fable.React.ReactElement"))], ["OnValueSelected", lambda_type(CheckfaceSrc$reflection(), unit_type)], ["Values", option_type(list_type(CheckfaceSrc$reflection()))]]);
}

export function item(renderValue, onValueSelected, value) {
    return createElement("div", {
        onClick: (_arg1) => {
            onValueSelected(value);
        },
        children: Interop_reactApi.Children.toArray([renderValue(value)]),
    });
}

export function parseMessageData(data) {
    let asGuid;
    const matchValue = data.guid;
    if ((typeof matchValue) === "string") {
        let matchValue_1;
        let tupledArg;
        let outArg = "00000000-0000-0000-0000-000000000000";
        tupledArg = [tryParse(matchValue, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        matchValue_1 = tryToOption(tupledArg[0], tupledArg[1]);
        asGuid = ((matchValue_1 == null) ? (void 0) : (new CheckfaceSrc(1, matchValue_1)));
    }
    else {
        asGuid = (void 0);
    }
    let asSeed;
    const matchValue_2 = data.seed;
    asSeed = (((typeof matchValue_2) === "number") ? (new CheckfaceSrc(2, matchValue_2 >>> 0)) : (void 0));
    let asValue;
    const matchValue_3 = data.value;
    asValue = (((typeof matchValue_3) === "string") ? (new CheckfaceSrc(0, matchValue_3)) : (void 0));
    return defaultArg(defaultArg(asGuid, asSeed), asValue);
}

export function BrowseFacesDialog(props) {
    const frameWindow = useReact_useElementRef();
    useReact_useEffect_Z5234A374(() => {
        const onMessage = (evt) => {
            const matchValue = frameWindow.current;
            if (matchValue != null) {
                const message = evt;
                if (equals(message.source, matchValue.contentWindow)) {
                    const matchValue_1 = parseMessageData(message.data);
                    if (matchValue_1 == null) {
                        console.error(some("Received malformed message from iframe"), message);
                    }
                    else {
                        props.OnValueSelected(matchValue_1);
                    }
                }
            }
        };
        window.addEventListener("message", onMessage);
        return React_createDisposable_3A5B6456(() => {
            window.removeEventListener("message", onMessage);
        });
    }, [props.OnValueSelected]);
    return MuiHelpers_createElement(Dialog, [["open", props.IsOpen], ["fullScreen", true], ["onClose", (delegateArg0, delegateArg1) => {
        props.OnClose();
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(DialogTitle, [["children", Interop_reactApi.Children.toArray([createElement("div", {
        style: {
            display: "flex",
        },
        children: Interop_reactApi.Children.toArray([createElement("div", {
            children: ["Select an Image"],
        }), createElement("div", {
            style: {
                flexGrow: 1,
            },
        }), MuiHelpers_createElement(IconButton, [["onClick", (_arg3) => {
            props.OnClose();
        }], ["children", Interop_reactApi.Children.toArray([react.createElement(Close, {})])]])]),
    })])]]), MuiHelpers_createElement(DialogContent, [["style", {
        padding: 0,
        overflowY: "visible",
        display: "flex",
    }], ["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (props.IsOpen ? singleton(createElement("iframe", {
        src: browseFacesEmbedSrc,
        style: {
            width: 100 + "%",
            height: 100 + "%",
        },
        ref: frameWindow,
    })) : empty())))))]])])]]);
}

