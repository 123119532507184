import { vidMorphAlt, vidSrc, morphframeSrc, $007CAsNumericSeed$007C, $007CAsTextValue$007C, CheckfaceSrc$reflection, shortCheckfaceSrcDesc, CheckfaceSrc, imgAlt, imgSrc } from "./Checkface.fs.js";
import { encodeApiAddr, videoDim, imgSizesSet, imgDim } from "./Config.fs.js";
import { interpolate, filter, printf, toText, join } from "../Server/.fable/fable-library.3.1.12/String.js";
import { empty as empty_1, singleton as singleton_1, ofArray, map } from "../Server/.fable/fable-library.3.1.12/List.js";
import { createElement } from "react";
import * as react from "react";
import { Interop_reactApi } from "../Server/.fable/Feliz.1.60.0/Interop.fs.js";
import { Union, FSharpRef, Record } from "../Server/.fable/fable-library.3.1.12/Types.js";
import { bool_type, union_type, record_type, list_type, lambda_type, unit_type, class_type, string_type } from "../Server/.fable/fable-library.3.1.12/Reflection.js";
import { MuiHelpers_createElement } from "../Server/.fable/Feliz.MaterialUI.1.2.6/Mui.fs.js";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { map as map_1, empty, singleton, append, delay, toList } from "../Server/.fable/fable-library.3.1.12/Seq.js";
import Menu from "@material-ui/icons/Menu";
import { isDigit } from "../Server/.fable/fable-library.3.1.12/Char.js";
import { tryParse } from "../Server/.fable/fable-library.3.1.12/Int32.js";
import Camera from "@material-ui/icons/Camera";
import ImageSearch from "@material-ui/icons/ImageSearch";
import Menu_1 from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { equals } from "../Server/.fable/fable-library.3.1.12/Util.js";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import TextFields from "@material-ui/icons/TextFields";
import ListItemText from "@material-ui/core/ListItemText";
import Dialpad from "@material-ui/icons/Dialpad";
import { useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "../Server/.fable/Feliz.1.60.0/React.fs.js";
import { column } from "../Server/.fable/Fulma.2.16.0/Layouts/Column.fs.js";
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Browser_Types_Event__Event_get_Checked, Browser_Types_Event__Event_get_Value } from "../Server/.fable/Fable.React.7.4.0/Fable.React.Extensions.fs.js";
import { Props, ViewSliderMorph } from "./SliderMorph.fs.js";
import { Side, Msg } from "./AppState.fs.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FancyButton } from "./FancyButton.fs.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import { EncodeImageDialogProps, EncodeImageDialog } from "./EncodeImageDialog.fs.js";
import { BrowseFacesDialogProps, BrowseFacesDialog } from "./BrowseFacesDialog.fs.js";
import { rangeDouble } from "../Server/.fable/fable-library.3.1.12/Range.js";

export function renderImageByValue(value) {
    const src = imgSrc(imgDim, false, value);
    const srcSet = (isWebp) => join(",", map((dim) => {
        const arg10 = imgSrc(dim, isWebp, value);
        return toText(printf("%s %iw"))(arg10)(dim);
    }, imgSizesSet));
    const sizes = toText(printf("%ipx"))(imgDim);
    const children = ofArray([createElement("source", {
        type: "image/webp",
        srcSet: srcSet(true),
        sizes: sizes,
    }), createElement("source", {
        type: "image/jpeg",
        srcSet: srcSet(false),
        sizes: sizes,
    }), createElement("img", {
        src: src,
        sizes: sizes,
        width: imgDim,
        height: imgDim,
        alt: imgAlt(value),
    })]);
    return createElement("picture", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

class InputConfig extends Record {
    constructor(Value, Placeholder, EndAdornment, StartAdornment, OnChange, ExtraTextfieldProps) {
        super();
        this.Value = Value;
        this.Placeholder = Placeholder;
        this.EndAdornment = EndAdornment;
        this.StartAdornment = StartAdornment;
        this.OnChange = OnChange;
        this.ExtraTextfieldProps = ExtraTextfieldProps;
    }
}

function InputConfig$reflection() {
    return record_type("MorphForm.InputConfig", [], InputConfig, () => [["Value", string_type], ["Placeholder", string_type], ["EndAdornment", class_type("Fable.React.ReactElement")], ["StartAdornment", class_type("Fable.React.ReactElement")], ["OnChange", lambda_type(string_type, unit_type)], ["ExtraTextfieldProps", list_type(class_type("Feliz.IReactProperty"))]]);
}

export function tooltippedIconbutton(title, props) {
    return MuiHelpers_createElement(Tooltip, [["title", title], ["children", MuiHelpers_createElement(IconButton, props)]]);
}

function getInputConfig(btnId, value, onChange, onUploadRealImage, onBrowseCheckfaceValues, onClickMenu) {
    const startAdornment = (hasFocusWithinClass) => tooltippedIconbutton("Change Mode", toList(delay(() => append(hasFocusWithinClass ? singleton(["className", "focuswithin-child"]) : empty(), delay(() => append(singleton(["edge", "start"]), delay(() => append(singleton(["children", react.createElement(Menu, {})]), delay(() => append(singleton(["onClick", (arg) => {
        onClickMenu(void arg);
    }]), delay(() => append(singleton(["aria-label", "change mode"]), delay(() => singleton(["id", btnId]))))))))))))));
    switch (value.tag) {
        case 2: {
            const seed = value.fields[0];
            const Value = (seed === 0) ? "" : seed.toString();
            const StartAdornment_1 = startAdornment(true);
            return new InputConfig(Value, "Input an integer seed", react.createElement(react.Fragment, {}), StartAdornment_1, (value_32) => {
                const onlyDigits = filter((arg00) => isDigit(arg00), value_32);
                let matchValue;
                let outArg = 0;
                matchValue = [tryParse(onlyDigits, 511, true, 32, new FSharpRef(() => outArg, (v) => {
                    outArg = v;
                })), outArg];
                if (value_32 !== onlyDigits) {
                }
                else if (value_32 === "") {
                    onChange(new CheckfaceSrc(2, 0));
                }
                else if (matchValue[0]) {
                    onChange(new CheckfaceSrc(2, matchValue[1]));
                }
            }, ofArray([["type", "number"], ["inputProps", {
                min: 0,
                max: 4294967295,
            }]]));
        }
        case 1: {
            const Value_1 = shortCheckfaceSrcDesc(new CheckfaceSrc(1, value.fields[0]));
            const StartAdornment_2 = startAdornment(false);
            return new InputConfig(Value_1, "Upload an image", tooltippedIconbutton("Upload Image", [["edge", "end"], ["children", react.createElement(Camera, {})], ["onClick", (_arg2) => {
                onUploadRealImage();
            }], ["aria-label", "upload image"]]), StartAdornment_2, (arg_2) => {
                onChange(new CheckfaceSrc(0, arg_2));
            }, singleton_1(["disabled", true]));
        }
        default: {
            const StartAdornment = startAdornment(true);
            return new InputConfig(value.fields[0], "Just type anything", tooltippedIconbutton("Browse Names", [["className", "focuswithin-child"], ["edge", "end"], ["children", react.createElement(ImageSearch, {})], ["onClick", (_arg1) => {
                onBrowseCheckfaceValues();
            }], ["aria-label", "browse names"]]), StartAdornment, (arg_1) => {
                onChange(new CheckfaceSrc(0, arg_1));
            }, singleton_1(["inputProps", {
                style: {
                    textAlign: "center",
                },
                autoCapitalize: "off",
            }]));
        }
    }
}

export class SetpointKind extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TextValue", "NumericSeed", "SomeGuid"];
    }
}

export function SetpointKind$reflection() {
    return union_type("MorphForm.SetpointKind", [], SetpointKind, () => [[], [], []]);
}

export function setpointKindMenu(anchorEl, isOpen, setMenuOpen, setpointKind, changeSetpointKind) {
    return MuiHelpers_createElement(Menu_1, [["anchorEl", () => anchorEl.current], ["keepMounted", true], ["open", isOpen], ["onClose", (_arg16, v) => {
        setMenuOpen(false);
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(MenuItem, [["selected", equals(setpointKind, new SetpointKind(0))], ["onClick", (_arg2) => {
        changeSetpointKind(new SetpointKind(0));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([react.createElement(TextFields, {})])]]), MuiHelpers_createElement(ListItemText, [["primary", "Text Value"]])])]]), MuiHelpers_createElement(MenuItem, [["selected", equals(setpointKind, new SetpointKind(1))], ["onClick", (_arg3) => {
        changeSetpointKind(new SetpointKind(1));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([react.createElement(Dialpad, {})])]]), MuiHelpers_createElement(ListItemText, [["primary", "Numeric Seed"]])])]]), MuiHelpers_createElement(MenuItem, [["selected", equals(setpointKind, new SetpointKind(2))], ["onClick", (_arg4) => {
        changeSetpointKind(new SetpointKind(2));
    }], ["children", Interop_reactApi.Children.toArray([MuiHelpers_createElement(ListItemIcon, [["children", Interop_reactApi.Children.toArray([react.createElement(Camera, {})])]]), MuiHelpers_createElement(ListItemText, [["primary", "Upload Image"]])])]])])]]);
}

class SetpointProps extends Record {
    constructor(AutoFocus, Label, Id, Value, OnChange, OnUploadRealImage, OnBrowseCheckfaceValues) {
        super();
        this.AutoFocus = AutoFocus;
        this.Label = Label;
        this.Id = Id;
        this.Value = Value;
        this.OnChange = OnChange;
        this.OnUploadRealImage = OnUploadRealImage;
        this.OnBrowseCheckfaceValues = OnBrowseCheckfaceValues;
    }
}

function SetpointProps$reflection() {
    return record_type("MorphForm.SetpointProps", [], SetpointProps, () => [["AutoFocus", bool_type], ["Label", string_type], ["Id", string_type], ["Value", CheckfaceSrc$reflection()], ["OnChange", lambda_type(CheckfaceSrc$reflection(), unit_type)], ["OnUploadRealImage", lambda_type(unit_type, unit_type)], ["OnBrowseCheckfaceValues", lambda_type(unit_type, unit_type)]]);
}

function SetpointInput(props) {
    const anchorEl = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const setMenuOpen = patternInput[1];
    const changeModeBtnId = toText(interpolate("%P()-changemodebtn", [props.Id]));
    const inputId = toText(interpolate("%P()-input", [props.Id]));
    const inputConfig = getInputConfig(changeModeBtnId, props.Value, props.OnChange, props.OnUploadRealImage, props.OnBrowseCheckfaceValues, () => {
        setMenuOpen(true);
    });
    let setpointKind;
    const matchValue = props.Value;
    switch (matchValue.tag) {
        case 2: {
            setpointKind = (new SetpointKind(1));
            break;
        }
        case 1: {
            setpointKind = (new SetpointKind(2));
            break;
        }
        default: {
            setpointKind = (new SetpointKind(0));
        }
    }
    return column(empty_1(), ofArray([createElement("div", {
        children: Interop_reactApi.Children.toArray([renderImageByValue(props.Value)]),
    }), MuiHelpers_createElement(NoSsr, [["children", Interop_reactApi.Children.toArray([setpointKindMenu(anchorEl, patternInput[0], setMenuOpen, setpointKind, (kind) => {
        setMenuOpen(false);
        const matchValue_1 = [kind, props.Value];
        if (matchValue_1[0].tag === 0) {
            props.OnChange(new CheckfaceSrc(0, $007CAsTextValue$007C(matchValue_1[1])));
        }
        else if (matchValue_1[0].tag === 2) {
            props.OnUploadRealImage();
        }
        else {
            props.OnChange(new CheckfaceSrc(2, $007CAsNumericSeed$007C(matchValue_1[1])));
        }
    })])]]), MuiHelpers_createElement(TextField, toList(delay(() => append(singleton(["value", inputConfig.Value]), delay(() => append(singleton(["InputProps", {
        startAdornment: MuiHelpers_createElement(InputAdornment, [["position", "start"], ["ref", anchorEl], ["children", Interop_reactApi.Children.toArray([inputConfig.StartAdornment])]]),
        endAdornment: MuiHelpers_createElement(InputAdornment, [["position", "end"], ["children", Interop_reactApi.Children.toArray([inputConfig.EndAdornment])]]),
    }]), delay(() => append(inputConfig.ExtraTextfieldProps, delay(() => append(singleton(["onChange", (e) => {
        inputConfig.OnChange(Browser_Types_Event__Event_get_Value(e));
    }]), delay(() => append(singleton(["placeholder", inputConfig.Placeholder]), delay(() => append(singleton(["autoFocus", props.AutoFocus]), delay(() => append(singleton(["style", {
        width: 100 + "%",
        maxWidth: imgDim + "px",
    }]), delay(() => append(singleton(["className", "focuswithin-parent"]), delay(() => append(singleton(["variant", "outlined"]), delay(() => append(singleton(["label", props.Label]), delay(() => append(singleton(["margin", "normal"]), delay(() => singleton(["id", inputId]))))))))))))))))))))))))))]));
}

export function renderMorph(values, useSlider, dispatch) {
    if (values != null) {
        const toValue = values[1];
        const fromValue = values[0];
        const children = toList(delay(() => {
            let posterImgSrc;
            return append(useSlider ? singleton(createElement(ViewSliderMorph, new Props([fromValue, toValue], () => {
                dispatch(new Msg(9));
            }, (tupledArg, dim, numFrames, frameNum) => morphframeSrc(tupledArg[0], tupledArg[1], dim, numFrames, frameNum), videoDim, 25))) : (posterImgSrc = imgSrc(imgDim, false, fromValue), append(singleton(createElement("img", {
                width: videoDim,
                height: videoDim,
                className: "morph-dummyImg",
            })), delay(() => singleton(createElement("video", {
                src: vidSrc(videoDim, fromValue, toValue),
                controls: true,
                autoPlay: true,
                loop: true,
                muted: true,
                style: {
                    display: "block",
                    margin: "auto",
                },
                className: "morph-vid",
                poster: posterImgSrc,
                width: videoDim,
                height: videoDim,
                alt: vidMorphAlt(fromValue, toValue),
                onLoadedData: (_arg2) => {
                    dispatch(new Msg(9));
                },
            }))))), delay(() => singleton(MuiHelpers_createElement(FormControlLabel, [["style", {
                display: "block",
                marginLeft: 5 + "px",
                justifySelf: "center",
                width: 100 + "%",
                maxWidth: videoDim + "px",
                height: 0 + "px",
            }], ["control", MuiHelpers_createElement(Checkbox, [["checked", useSlider], ["onChange", (e) => {
                dispatch(new Msg(10, Browser_Types_Event__Event_get_Checked(e)));
            }]])], ["label", "Use Slider"]]))));
        }));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return null;
    }
}

export function morphButton(isLoading, hideButton) {
    return column(empty_1(), singleton_1(createElement(FancyButton, {
        buttonProps: toList(delay(() => append((hideButton ? isLoading : false) ? singleton(["style", {
            opacity: 0.7,
        }]) : (hideButton ? singleton(["style", {
            opacity: 0,
        }]) : empty()), delay(() => append(singleton(["type", "submit"]), delay(() => append(singleton(["color", "primary"]), delay(() => append(singleton(["variant", "contained"]), delay(() => append(singleton(["size", "large"]), delay(() => append(singleton(["disabled", isLoading ? true : hideButton]), delay(() => singleton(["children", Interop_reactApi.Children.toArray(Array.from(toList(delay(() => (isLoading ? singleton(MuiHelpers_createElement(CircularProgress, [["size", 20], ["color", "inherit"]])) : singleton("Morph"))))))]))))))))))))))),
    })));
}

export function renderContent(state, dispatch) {
    return createElement("form", {
        onSubmit: (e) => {
            e.preventDefault();
            dispatch(new Msg(7));
        },
        children: Interop_reactApi.Children.toArray([MuiHelpers_createElement(Container, [["children", Interop_reactApi.Children.toArray([createElement("div", {
            className: "morph-content",
            children: Interop_reactApi.Children.toArray(Array.from(toList(delay(() => append(singleton(createElement(SetpointInput, new SetpointProps(true, "Morph from", "leftval", state.LeftValue, (arg) => {
                dispatch(new Msg(0, arg));
            }, () => {
                dispatch(new Msg(2, new Side(0)));
            }, () => {
                dispatch(new Msg(3, new Side(0)));
            }))), delay(() => append(singleton(createElement(SetpointInput, new SetpointProps(false, "Morph to", "rightval", state.RightValue, (arg_1) => {
                dispatch(new Msg(1, arg_1));
            }, () => {
                dispatch(new Msg(2, new Side(1)));
            }, () => {
                dispatch(new Msg(3, new Side(1)));
            }))), delay(() => append(singleton(morphButton(state.IsMorphLoading, equals(state.VidValues, [state.LeftValue, state.RightValue]))), delay(() => singleton(renderMorph(state.VidValues, state.UseSlider, dispatch)))))))))))),
        })])]])]),
    });
}

export function renderEncodeImageDialog(state, dispatch) {
    return createElement(EncodeImageDialog, new EncodeImageDialogProps(() => {
        dispatch(new Msg(4));
    }, state.UploadDialogSide != null, (arg) => renderImageByValue(new CheckfaceSrc(1, arg)), encodeApiAddr, (guid) => {
        dispatch(new Msg(4));
        const matchValue = state.UploadDialogSide;
        if (matchValue == null) {
        }
        else if (matchValue.tag === 1) {
            dispatch(new Msg(1, new CheckfaceSrc(1, guid)));
        }
        else {
            dispatch(new Msg(0, new CheckfaceSrc(1, guid)));
        }
    }));
}

export function renderBrowseFacesDialog(state, dispatch) {
    return createElement(BrowseFacesDialog, new BrowseFacesDialogProps(() => {
        dispatch(new Msg(5));
    }, state.BrowseFacesDialogSide != null, (value) => renderImageByValue(value), (value_1) => {
        dispatch(new Msg(5));
        const matchValue = state.BrowseFacesDialogSide;
        if (matchValue == null) {
        }
        else if (matchValue.tag === 1) {
            dispatch(new Msg(1, value_1));
        }
        else {
            dispatch(new Msg(0, value_1));
        }
    }, toList(delay(() => map_1((i) => (new CheckfaceSrc(2, i)), rangeDouble(1, 1, 100))))));
}

